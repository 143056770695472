import React, { useEffect, useState } from "react";
import "./App.css";
import { Route, Routes, useNavigate } from "react-router";
import { Layout } from "./Pages/Layout/Layout";
import { MyProfile } from "./Pages/Views/My_Profile/MyProfile";
import { DoctorList } from "./Pages/Views/Doctor_list/DoctorList";
import Consultation from "./Pages/Views/Consultation/Consultation";
import { MyDoctor } from "./Pages/Views/My_Doctor/MyDoctor";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { Preloader } from "./Components/Preloader/Preloader";
import { LandingLoader } from "./Pages/Views/Landing/LandingLoader";
import { Landing } from "./Pages/Views/Landing/landing";
import Main from "./Pages/Views/Main/Main";
import { axiosConfig } from "./base/asyncActions/getConfig";
import { Login } from "./Pages/Views/Login/Login";
import Recording from "./Pages/Views/Recording/Recording";
import Razdeli from "./Pages/Views/Razdeli/Razdeli";
import { getShortInfo } from "./base/asyncActions/getMainPageInfo";
import { Register } from "./Pages/Views/Register/Register";
import Payment from "./Pages/Views/Payment/Payment";
import NotFound from "./Pages/Views/NotFound/NotFound";
import Balance from "./Pages/Views/My_Profile/Balance/Balance";
import Result from "./Pages/Views/My_Profile/Result/Result";
import MedCart from "./Pages/Views/My_Profile/Med_Cart/Med_Cart";
import LocalData from "./Pages/Views/My_Profile/Local_Data/Local_Data";
import ReplaceData from "./Pages/Views/My_Profile/Replace_Data/ReplaceData";
import Utility from "./Pages/Views/My_Profile/Utility/Utility";
import Messages from "./Pages/Views/My_Profile/Messages/Messages";
import PrivatePolice from "./Pages/Views/PrivatePolice.jsx/PrivatePolice";
import Video from "./Pages/Views/Video/Video";
import ConsultationResult from "./Pages/Views/ConsultationResult/ConsultationResult";
import SliderApp from "./Components/Sliders/SliderApp/SliderApp";
import "./config.css";
import "react-loading-skeleton/dist/skeleton.css";
import { useLocation } from "react-router-dom";
import {
  nativeSendMessage,
  useNativeGetMessage,
} from "./base/nativeController";
import { Navigate } from "react-router-dom";
import { getRoute } from "./base/utils";
import PaymentLink from "./Pages/Views/PaymentLink/PaymentLink";
import Install from "./Pages/Views/Install/Install";
import ModalContainerNew from "./Components/Modal/ModalContainerNew";
import Button from "./Components/Button/Button";

function App() {
  const dispatch = useDispatch();
  const config = useSelector((state) => state.config.config);
  const loading = useSelector((state) => state.config.loading);
  const [route, setRoute] = useState(null);

  const nativeGetMessage = useNativeGetMessage();
  const location = useLocation();

  useEffect(() => {
    nativeSendMessage({ action: "appLoaded" });
    dispatch(axiosConfig());
    dispatch(getShortInfo());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const route = getRoute(location);
    nativeSendMessage({
      action: "currentPage",
      data: { path: location, route },
    });
  }, [location]);

  useEffect(() => {
    function handleEvent(message) {
      nativeGetMessage(message.data);
    }
    document.addEventListener("message", handleEvent);

    return () => document.removeEventListener("message", handleEvent);
  }, [nativeGetMessage]);

  useEffect(() => {
    nativeSendMessage({
      action: "getUser",
      data: { isAuthorized: !!config?.token },
    });
    document.documentElement.style.setProperty(
      "--black",
      config?.config.colors.color2
    );
    document.documentElement.style.setProperty(
      "--blueOpacity",
      config?.config.colors.color3
    );
    document.documentElement.style.setProperty(
      "--title",
      config?.config.colors.color5
    );
    document.documentElement.style.setProperty(
      "--white",
      config?.config.colors.color1
    );
    document.documentElement.style.setProperty(
      "--blue",
      config?.config.colors.color10
    );
    document.documentElement.style.setProperty(
      "--blue-hover",
      config?.config.colors.color11
    );
    document.documentElement.style.setProperty(
      "--blue",
      config?.config.colors.color10
    );
    document.documentElement.style.setProperty(
      "--swiper-theme-color",
      config?.config.colors.color10
    );
    document.documentElement.style.setProperty(
      "--green",
      config?.config.colors.color8
    );
    document.documentElement.style.setProperty(
      "--gray",
      config?.config.colors.color4
    );
    document.documentElement.style.setProperty(
      "--orange",
      config?.config.colors.color7
    );
  }, [config]);

  const navigate = useNavigate();

  const detectMobileDevice = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
      return "android";
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "ios";
    }

    return false;
  };

  const [openModalInstall, setOpenModalInstall] = useState(
    detectMobileDevice()
  );

  useEffect(() => {
    const route = getRoute(location);
    setRoute(route);
  }, [location]);

  useEffect(() => {
    if (config?.module?.javascript) {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.innerHTML = config.module?.javascript;
      document.head.appendChild(script);

      // Очистка: удаление скрипта при размонтировании компонента
      return () => {
        document.head.removeChild(script);
      };
    }
  }, [config]);

  const isNotPublicPage = () => {
    return (
      (route &&
        route !== "docs/:type" &&
        route !== "pay/:id" &&
        route !== "install") ||
      route === false
    );
  };

  return (
    <>
      {!loading ? (
        <Preloader />
      ) : (
        <>
          {config?.platform !== "app" &&
          isNotPublicPage() &&
          detectMobileDevice() &&
          sessionStorage.getItem("installAppIgnore") !== "1" ? (
            <ModalContainerNew
              openModal={openModalInstall}
              setOpenModal={(status) => {
                setOpenModalInstall(status);
              }}
              onClose={() => {
                sessionStorage.setItem("installAppIgnore", "1");
              }}
              classPost="smallModal"
            >
              <div style={{ maxHeight: "40vh", textAlign: "center" }}>
                <>
                  <h2 style={{ marginTop: "10px", marginBottom: "20px" }}>
                    Хотите установить наше приложение?
                  </h2>
                  <Button
                    type={"submit"}
                    class={"btn blue"}
                    text={"Установить"}
                    onClick={() => {
                      navigate("/install");
                    }}
                  />
                </>
              </div>
            </ModalContainerNew>
          ) : null}
          <Routes>
            {false && config?.module?.landing?.is_active ? (
              <Route
                index
                element={
                  <LandingLoader>
                    <Landing
                      title={config?.config.title}
                      html={config.module.landing.html}
                    />
                  </LandingLoader>
                }
              />
            ) : config?.module?.welcome_screen?.is_active ? (
              <Route index element={<SliderApp />} />
            ) : (
              <Route
                index
                element={
                  <>
                    <Navigate to={"/login"} />
                  </>
                }
              />
            )}

            <Route path={"login"} element={<Login />} />
            <Route path={"register"} element={<Register />} />
            <Route path={"/"} element={<Layout />}>
              <Route path={"main"} element={<Main />} />
              <Route path={"profile"} element={<MyProfile />}>
                <Route path={"view"} element={<LocalData />} />
                <Route path={"edit"} element={<ReplaceData />} />
                <Route path={"balance"} element={<Balance />} />
                <Route path={"research"} element={<Result />} />
                <Route path={"medcard"} element={<MedCart />} />
                <Route path={"utility"} element={<Utility />} />
                <Route path={"notifications"} element={<Messages />} />
              </Route>
              <Route path={"medical-direction"} element={<Razdeli />} />
              <Route path={"doctors/:code"} element={<DoctorList />} />
              <Route
                path={"doctors/:code/:spec_code"}
                element={<DoctorList />}
              />
              <Route path={"doctor/:id/:type"} element={<Recording />} />
              <Route path={"doctor/:id"} element={<Recording />} />
              <Route path={"payment/:id"} element={<Payment />} />
              <Route path={"my-consultation"} element={<Consultation />} />
              <Route path={"my-doctor"} element={<MyDoctor />} />
              <Route path={"consultation/:id"} element={<Video />} />
              <Route
                path={"consultation/:id/result"}
                element={<ConsultationResult />}
              />
              <Route path={"docs/:type"} element={<PrivatePolice />} />
              <Route path={"pay/:id"} element={<PaymentLink />} />
              <Route path={"install"} element={<Install />} />
              <Route path={"*"} element={<NotFound />} />
            </Route>
          </Routes>
        </>
      )}
    </>
  );
}

export default App;

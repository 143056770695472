import React, { Component } from "react";
import "./StreamComponent.css";

export default class OvVideoComponent extends Component {
  constructor(props) {
    super(props);
    this.videoRef = React.createRef();
  }

  componentDidMount() {
    if (this.props && this.props.user.streamManager && !!this.videoRef) {
      this.props.user.getStreamManager().addVideoElement(this.videoRef.current);
    }

    if (!!this.videoRef && this.videoRef.current) {
      this.videoRef.current.setAttribute("playsinline", "true");
      this.videoRef.current.setAttribute("webkit-playsinline", "true");
      this.videoRef.current.setAttribute("muted", "true");
      this.videoRef.current.setAttribute("autoplay", "true");
    }

    if (
      this.props &&
      this.props.user.streamManager.session &&
      this.props.user &&
      !!this.videoRef
    ) {
      this.props.user.streamManager.session.on(
        "signal:userChanged",
        (event) => {
          const data = JSON.parse(event.data);
          if (data.isScreenShareActive !== undefined) {
            this.props.user
              .getStreamManager()
              .addVideoElement(this.videoRef.current);
          }
        }
      );
    }
  }

  componentDidUpdate(props) {
    if (props && !!this.videoRef) {
      this.props.user.getStreamManager().addVideoElement(this.videoRef.current);
    }
  }

  render() {
    return (
      <>
        <video
          style={{
            width: "100%",
            height: "auto",
            display: "block",
            objectFit: "contain",
          }}
          autoPlay
          id={"video-" + this.props.user.getStreamManager().stream.streamId}
          ref={this.videoRef}
          muted={this.props.mutedSound}
          playsInline // Correct casing
          webkitPlaysInline // Correct casing
          preload="auto"
        />
      </>
    );
  }
}

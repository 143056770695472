import { useUnit } from "effector-react";
import {
  setUserDevice,
  setUserDeviceToken,
} from "./asyncActions/setUserDevice";
import { setAppEvent } from "./store/appStore";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useHistoryUp } from "./utils";

export const nativeSendMessage = (message) => {
  console.log("nativeSendMessage", message);
  if (window?.ReactNativeWebView?.postMessage) {
    window.ReactNativeWebView.postMessage(JSON.stringify(message));
  }
};

export const nativeSendLog = (message) => {
  nativeSendMessage({ action: "log", data: message });
};

export const useNativeGetMessage = () => {
  const [setApp] = useUnit([setAppEvent]);
  const navigate = useNavigate();
  const historyUp = useHistoryUp();

  return useCallback(
    async (data) => {
      switch (data.action) {
        case "deviceInfo":
          const device = await setUserDevice(data.value);
          if (data.value?.expoPushToken == null) {
            setApp({
              deviceId: device.deviceId,
              showPushModal:
                device?.pushEnabled == null ?? !device?.pushEnabled,
            });
            nativeSendMessage({
              action: "pushEnabled",
              status: device?.pushEnabled,
            });
          }
          break;

        case "pushToken":
          if (data.value?.deviceId != null) {
            await setUserDeviceToken(
              data.value.deviceId,
              data.value.expoPushToken
            );
          }
          break;

        case "navigate":
          if (data.value) {
            navigate(data.value);
          }
          break;

        case "navigateBack":
          historyUp();
          break;

        default:
          break;
      }
    },
    [historyUp, setApp, navigate]
  );
};

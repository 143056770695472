import React from "react";
import s from "./ModalContainerNew.module.css";
import { useEffect } from "react";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import { nativeSendMessage } from "../../base/nativeController";

const ModalContainerNew = (props) => {
  const isMobile = window.screen.availWidth <= 480;

  useEffect(() => {
    if (!props.openModal) {
      document.body.style.overflow = "auto";
      if (!props?.ignoreAllowRefresh) {
        nativeSendMessage({
          action: "allowRefresh",
          data: { status: true },
        });
      }
    }
    if (props.openModal) {
      if (!props?.ignoreAllowRefresh) {
        nativeSendMessage({
          action: "allowRefresh",
          data: { status: false },
        });
      }
      document.body.style.overflow = "hidden";
    }
  }, [props?.ignoreAllowRefresh, props.openModal]);

  const onClose = () => {
    if (props.onClose) {
      props.onClose();
    }
    props.setOpenModal(false);
  };

  return props.openModal ? (
    <>
      {isMobile ? (
        <BottomSheet
          open={props.openModal}
          onDismiss={() => onClose()}
          snapPoints={({ maxHeight }) => {
            if (props.classPost === "smallModal") {
              return [maxHeight / 2, maxHeight * 0.6];
            }
            return [maxHeight - maxHeight / 5, maxHeight * 0.8];
          }}
        >
          {props.title ? (
            <h1
              style={{ textAlign: "center" }}
              className={s.title + " title_config"}
            >
              {props.title}
            </h1>
          ) : null}
          {props.children}
        </BottomSheet>
      ) : (
        <div className={s.ModalContainer}>
          <div
            className={s.background}
            onClick={() => {
              onClose();
            }}
          />
          <div className={s.ModalContainer_block}>
            <div className={s.ModalContainer_title}>
              {props.title ? (
                <h1
                  tyle={{ textAlign: "center" }}
                  className={s.title + " title_config"}
                >
                  {props.title}
                </h1>
              ) : null}
              <div
                className={s.ModalContainer_close + " " + s.black}
                onClick={() => onClose()}
              >
                &times;
              </div>
            </div>
            <div
              className={
                s.ModalContainer_content +
                " " +
                (props.classPost ? s[props.classPost] : "")
              }
            >
              {props.children}
            </div>
          </div>
        </div>
      )}
    </>
  ) : null;
};
export default ModalContainerNew;
